import { graphql, useStaticQuery } from 'gatsby'
import Cookies from 'js-cookie'
import React, { FC, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import GABanner from './banner'

const COOKIE_NAME = 'cookie-opt-in'
const COOKIE_ATTR: Cookies.CookieAttributes = {
  expires: 365 * 3,
}

const query = graphql`
  query GA {
    site {
      siteMetadata {
        gaMeasurementId
      }
    }
  }
`

const GAScript: FC<{ gaMeasurementId: string }> = ({ gaMeasurementId }) => (
  <Helmet>
    {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
    <script
      async
      // src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`}
      src={`https://www.googletagmanager.com/gtm.js?id=${gaMeasurementId}`}
    ></script>
  </Helmet>
)

const GATag: FC<{ gaMeasurementId: string }> = () => (
  <Helmet>
    <script>{`
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
    `}</script>
  </Helmet>
)

const GA: FC = ({ children }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query)

  const [optedIn, setOptedIn] = useState<boolean>()

  useEffect(() => {
    const c = Cookies.get(COOKIE_NAME)
    if (c === '1') setOptedIn(true)
    if (c === undefined) setOptedIn(false)
  }, [])

  const onAccept = () => {
    Cookies.set(COOKIE_NAME, '1', COOKIE_ATTR)
    setOptedIn(true)
  }
  const onReject = () => {
    Cookies.set(COOKIE_NAME, '0', COOKIE_ATTR)
    setOptedIn(undefined)
  }

  const contentAlways = (
    <>
      <GATag gaMeasurementId={siteMetadata.gaMeasurementId} />
      {children}
    </>
  )

  if (optedIn === undefined) return contentAlways /* no ssr */

  if (optedIn) {
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname.includes('netlify')
    ) {
      return contentAlways /* don't track localhost or netlify urls */
    } else {
      return (
        <>
          <GAScript gaMeasurementId={siteMetadata.gaMeasurementId} />
          {contentAlways}
        </>
      )
    }
  } else {
    return (
      <>
        <GABanner onAccept={onAccept} onReject={onReject} />
        {contentAlways}
      </>
    )
  }
}

export default GA
