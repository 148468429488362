import React, { FC, SVGProps } from 'react'

/**
 * Transparent Thin
 */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 409.94 380" {...props}>
    <defs>
      <style>{`
        .cookie-cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:4px}
      `}</style>
    </defs>
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <path
          className="cookie-cls-1"
          d="M339.25 228.35c-10.86-10.86-11.77-26.46-9.12-40.83a154.57 154.57 0 01-32.42-11.7c-11.48-5.78-22.45-13.34-30.31-23.65s-11.59-23.4-11.62-36.19c-.05-20.11 7.7-40.83 22.43-54.46-15.65-5-30.7-13.45-39.84-26.87-6.68-9.81-8.17-21.24-6.35-32.47-2.31-.11-4.65-.18-7-.18C126.42 2 2 96.42 2 195s98.42 183 197 183c84 0 151.15-59.83 169.2-138.51-10.75-.55-21.28-3.49-28.95-11.14zM407.58 145.69c-2.05 8.6-13.93 12.46-21.73 12.82-4.51.2-9.36-1.42-11.85-5.17-4.35-6.57-1-18.05 1.64-24.64 1.48-3.69 3.61-7.26 6.86-9.55 7.5-5.26 15.25 0 19.42 6.68 5.08 8.07 6.88 14.71 5.66 19.86zM353 26.5c0 9.66-6.34 11.5-16 11.5s-19-1.84-19-11.5S320.34 6 330 6s23 10.84 23 20.5zM327.84 108.65c-.92 5.6-6.22 8.11-9.71 8.34a5.41 5.41 0 01-5.29-3.36c-1.95-4.27-.46-11.74.73-16a14.45 14.45 0 013.07-6.21c3.34-3.42 6.8 0 8.67 4.34 2.26 5.24 3.07 9.54 2.53 12.89z"
        />
        <path
          className="cookie-cls-1"
          d="M101.5 164c0 13-4 22.5-17 22.5s-26-8-26-21 9-18 22-18 21 3.5 21 16.5zM256.5 290.5c0 16.29-3.71 25-20 25s-29-9.71-29-26 9.71-23 26-23 23 7.71 23 24zM130.5 257.5c0 13.81-16.19 25-30 25a25 25 0 01-25-25c0-13.81 13.19-27 27-27s28 13.19 28 27zM203.5 128.5c0 15.74-4.26 21-20 21s-31-8.76-31-24.5 4.26-28.5 20-28.5 31 16.26 31 32zM204 214.27c0 7.64-2.31 11.73-12.45 11.73s-18.05-4.56-18.05-12.2 6-10.8 16.18-10.8S204 206.62 204 214.27z"
        />
        <path d="M202 20.75c-9-.43-18 2.37-26.36 5.57a223.43 223.43 0 00-32.08 15.87C124.53 53.32 105.82 66 89.5 80.9c-1.19 1.09.58 2.85 1.77 1.77 15.84-14.44 34-26.76 52.38-37.63a224.53 224.53 0 0131.56-15.88c8.44-3.33 17.6-6.35 26.79-5.91 1.61.08 1.6-2.42 0-2.5zM343.29 259.17c-4.73 14.94-12.83 31.9-24.51 42.67-1.19 1.09.58 2.86 1.76 1.77 12-11.1 20.28-28.36 25.17-43.78.48-1.53-1.93-2.19-2.42-.66zM313.94 308.29a1.25 1.25 0 000-2.5 1.25 1.25 0 000 2.5zM84 87.82a1.25 1.25 0 000-2.5 1.25 1.25 0 000 2.5zM76 91.8c-8.07 6.85-14.37 15.56-20.57 24.07-1 1.3 1.22 2.55 2.16 1.26 6.08-8.35 12.26-16.84 20.18-23.56 1.23-1-.55-2.81-1.77-1.77z" />
      </g>
    </g>
  </svg>
)

/**
 * Transparent Thick
 */
const SvgComponent_2: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 414.44 386" {...props}>
    <defs>
      <style>
        {'.cls-2{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:7px}'}
      </style>
    </defs>
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <path
          d="M342.25 231.35c-10.86-10.86-11.77-26.46-9.12-40.83a154.57 154.57 0 01-32.42-11.7c-11.48-5.78-22.45-13.34-30.31-23.65s-11.59-23.4-11.62-36.19c0-20.11 7.7-40.83 22.43-54.46-15.65-5-30.7-13.45-39.84-26.87-6.68-9.81-8.17-21.24-6.35-32.47-2.31-.11-4.65-.18-7-.18C129.42 5 5 99.42 5 198s98.42 183 197 183c84 0 151.15-59.83 169.2-138.51-10.75-.55-21.28-3.49-28.95-11.14z"
          strokeWidth="10px"
          fill="none"
          stroke="#000"
          strokeMiterlimit={10}
        />
        <path
          className="cls-2"
          d="M410.58 148.69c-2.05 8.6-13.93 12.46-21.73 12.82-4.51.2-9.36-1.42-11.85-5.17-4.35-6.57-1-18.05 1.64-24.64 1.48-3.69 3.61-7.26 6.86-9.55 7.5-5.26 15.25 0 19.42 6.68 5.08 8.07 6.88 14.71 5.66 19.86zM356 29.5c0 9.66-6.34 11.5-16 11.5s-19-1.84-19-11.5S323.34 9 333 9s23 10.84 23 20.5zM330.84 111.65c-.92 5.6-6.22 8.11-9.71 8.34a5.41 5.41 0 01-5.29-3.36c-1.95-4.27-.46-11.74.73-16a14.45 14.45 0 013.07-6.21c3.34-3.42 6.8 0 8.67 4.34 2.26 5.24 3.07 9.54 2.53 12.89zM104.5 167c0 13-4 22.5-17 22.5s-26-8-26-21 9-18 22-18 21 3.5 21 16.5zM259.5 293.5c0 16.29-3.71 25-20 25s-29-9.71-29-26 9.71-23 26-23 23 7.71 23 24zM133.5 260.5c0 13.81-16.19 25-30 25a25 25 0 01-25-25c0-13.81 13.19-27 27-27s28 13.19 28 27zM206.5 131.5c0 15.74-4.26 21-20 21s-31-8.76-31-24.5 4.26-28.5 20-28.5 31 16.26 31 32zM207 217.27c0 7.64-2.31 11.73-12.45 11.73s-18.05-4.56-18.05-12.2 6-10.8 16.18-10.8S207 209.62 207 217.27z"
        />
        <path d="M204.5 24.75c-9.45-.46-19 2.25-27.77 5.43a226.38 226.38 0 00-31.67 14.62c-19.14 10.47-38.15 22.61-54.33 37.34-3.58 3.25 1.74 8.54 5.3 5.3 15.39-14 33.46-25.48 51.62-35.5a216.22 216.22 0 0131.07-14.53c8.21-3 17-5.59 25.78-5.16 4.82.23 4.81-7.27 0-7.5zM343.88 261.5c-4.61 14.57-12.49 31.08-23.87 41.57-3.56 3.28 1.76 8.57 5.3 5.31 12.34-11.38 20.8-29.09 25.81-44.88 1.46-4.61-5.78-6.59-7.24-2zM313.94 322.79c4.82 0 4.83-7.5 0-7.5s-4.84 7.5 0 7.5zM221 30.32c4.83 0 4.84-7.5 0-7.5s-4.83 7.5 0 7.5zM77.22 93c-8.23 7-14.64 15.9-21 24.58-2.84 3.9 3.66 7.65 6.48 3.78 6-8.18 12-16.47 19.79-23.06 3.68-3.13-1.65-8.41-5.31-5.3z" />
      </g>
    </g>
  </svg>
)

export default SvgComponent_2
