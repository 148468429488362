import React, { FC } from 'react'
import Cookie from './cookie'

const Banner: FC<{ onAccept: () => void; onReject: () => void }> = ({
  onAccept,
  onReject,
}) => (
  <>
    <div className="fixed top-0 w-full z-50 bg-green">
      <div className="container mx-auto px-4 py-4">
        <div>
          <b>Cookie-Einstellungen</b>
          <p>
            Damit Sie unsere Seite vollständig nutzen können, sind Cookies
            erforderlich. Einige dieser Cookies sind notwendig, damit unsere
            Seite überhaupt funktioniert, andere Cookies sind nur für ein
            komfortables Nutzungserlebnis oder unsere Statistiken erforderlich.
          </p>
          <p>
            Bitte stimmen Sie all unseren Cookies zu und beachten Sie, dass
            unsere Seite für Sie nicht mehr vollständig funktioniert, wenn Sie
            in einige Cookies nicht einwilligen. Sie können Ihre Einwilligung
            jederzeit widerrufen. Einen Widerrufslink und mehr Informationen zu
            Cookies finden Sie in unserer Datenschutzerklärung.
          </p>
        </div>
        <div className="flex flex-wrap items-center justify-end">
          <Cookie className="mt-2 mr-2 h-8" />
          <div className="flex-1"></div>
          <button
            className="btn btn-dense btn-flat mt-2 ml-2"
            onClick={onReject}
          >
            Nein, keine Cookies bitte
          </button>
          <button className="btn btn-dense mt-2 ml-2" onClick={onAccept}>
            Einverstanden
          </button>
        </div>
      </div>
    </div>
    <div className="h-64 lg:h-56 xl:h-48"></div>
  </>
)

export default Banner
